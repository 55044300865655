$(function(){
    $('[data-moveTo]').on('click', function(){
        var speed = 500;
        var href= $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top - $('header').height();
        if ($(window).width() < 768 && $('[data-nav]').css('display', 'block')) {
            $('[data-nav-trigger]').trigger('click');
          }
        $('html, body').animate({scrollTop:position}, speed, 'swing');
        return false;
    });
});
