$(function () {
    $('.mizuta-keyvisual-slider').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1500,
        fade: true,
        dots: false,
        arrows: false,
    });

    $('.mizuta-kodawari').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1500,
        dots: true,
        dotsClass: 'mizuta-kodawari-dots',
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
    });
});
